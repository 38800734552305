import { motion } from 'framer-motion';

export default function Hero() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  const scrollToNextStep = (e) => {
    e.preventDefault();
    const nextStepSection = document.getElementById('next-step');
    nextStepSection.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToHowItWorks = (e) => {
    e.preventDefault();
    const howItWorksSection = document.getElementById('how-it-works');
    howItWorksSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <header className="relative bg-slate-50 text-slate-800 pt-40 pb-40 overflow-hidden">
      {/* Sketch-style background elements */}
      <div className="absolute inset-0 overflow-hidden">
        {/* Updated background pattern to match Features.js */}
        <div className="absolute inset-0" style={{
          backgroundImage: `
            radial-gradient(circle, rgba(0,0,0,0.03) 1px, transparent 1px),
            radial-gradient(circle, rgba(0,0,0,0.03) 1px, transparent 1px)
          `,
          backgroundSize: '40px 40px',
          backgroundPosition: '0 0, 20px 20px'
        }}></div>

        {/* Updated decorative elements to match Features.js style */}
        <div className="absolute top-40 right-20 w-[400px] h-[400px] 
             border-[6px] border-dashed border-orange-100/30 rounded-full 
             animate-[spin_30s_linear_infinite]"></div>
        
        <div className="absolute -bottom-20 -left-20 w-[300px] h-[300px] 
             border-[4px] border-orange-100/40 rounded-full 
             transform rotate-12"></div>

        {/* Added squiggly line from Features.js */}
        <svg className="absolute top-20 left-20 w-40 h-40 text-orange-100/30" viewBox="0 0 100 100">
          <path d="M10,50 Q25,25 40,50 T70,50 T100,50" 
                fill="none" stroke="currentColor" strokeWidth="2"
                className="animate-[dash_20s_linear_infinite]"/>
        </svg>
      </div>
      
      <motion.div className="max-w-7xl mx-auto px-6 relative z-10"
        initial="hidden" animate="visible" variants={containerVariants}>
        <div className="max-w-6xl mx-auto text-center">
          {/* Updated badge styling */}
          <motion.div variants={itemVariants} className="inline-block mb-10">
            <span className="inline-flex items-center px-8 py-3 rounded-full text-sm font-medium 
                           bg-white border-2 border-slate-200 
                           shadow-[4px_4px_0px_0px_rgba(0,0,0,0.1)]
                           hover:shadow-[6px_6px_0px_0px_rgba(0,0,0,0.12)]
                           transform hover:-translate-y-0.5 transition-all duration-300">
              <span className="mr-3">✨</span>
              Amazon PPC Specialists
              <span className="ml-3">✨</span>
            </span>
          </motion.div>

          {/* Updated heading with new color scheme */}
          <motion.h1 variants={itemVariants}
            className="text-6xl md:text-7xl font-bold mb-10 leading-tight tracking-tight">
            Where Human Expertise Meets
            <div className="relative inline-block mt-3">
              <span className="text-slate-900 relative">
                Truly<span className="text-orange-500">PPC</span>
                <svg className="absolute -bottom-4 left-0 w-full" height="10" viewBox="0 0 300 10">
                  <path d="M0,5 Q75,2 150,5 T300,5" fill="none" stroke="#f97316" strokeWidth="2"
                        className="animate-[dash_3s_linear_infinite]"/>
                </svg>
              </span>
            </div>
          </motion.h1>

          <motion.p 
            variants={itemVariants}
            className="text-lg md:text-xl text-slate-700 max-w-4xl mx-auto mb-10 
                       leading-relaxed font-normal tracking-wide">
            Welcome to TrulyPPC, where Amazon advertising isn't just another service—it's our core focus. We're a dedicated team of specialists who combine human expertise with AI-driven precision to optimize your PPC campaigns.
          </motion.p>

          <motion.p 
            variants={itemVariants}
            className="text-lg md:text-xl text-slate-700 max-w-3xl mx-auto mb-14 
                       leading-relaxed font-normal tracking-wide">
            Currently serving clients with our proprietary PPC tool, we're preparing to share this powerful technology with you. Partner with us for data-driven success and personalized attention to every campaign.
          </motion.p>

          {/* Updated CTA buttons */}
          <motion.div variants={itemVariants}
            className="flex flex-col sm:flex-row gap-8 justify-center items-center">
            <a href="#next-step" 
              onClick={scrollToNextStep}
              className="group w-full sm:w-auto px-10 py-5 rounded-2xl font-semibold
                        bg-orange-500 text-white
                        shadow-[6px_6px_0px_0px_rgba(249,115,22,0.3)]
                        hover:shadow-[8px_8px_0px_0px_rgba(249,115,22,0.35)]
                        transform hover:-translate-y-0.5 transition-all duration-300">
              <span className="relative z-10">Start Your PPC Journey</span>
              <span className="inline-block ml-2 transition-transform group-hover:translate-x-2">→</span>
            </a>
            <a href="#how-it-works" 
              onClick={scrollToHowItWorks}
              className="group w-full sm:w-auto bg-white
                        border-2 border-slate-200
                        px-10 py-5 rounded-2xl font-semibold text-slate-900
                        shadow-[4px_4px_0px_0px_rgba(0,0,0,0.1)]
                        hover:shadow-[6px_6px_0px_0px_rgba(0,0,0,0.12)]
                        transform hover:-translate-y-0.5 transition-all duration-300">
              Learn Our Approach
              <span className="inline-block ml-2 transition-transform group-hover:translate-x-2">→</span>
            </a>
          </motion.div>
        </div>
      </motion.div>
    </header>
  );
} 