import { motion } from "framer-motion";
import { faArrowRight, faRocket, faComments } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

function NextStep() {
  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.8, ease: "easeOut" }
  };

  return (
    <section id="next-step" className="relative py-48 bg-slate-50 overflow-hidden">
      {/* Updated Background Elements */}
      <div className="absolute inset-0">
        {/* Hand-drawn circles */}
        <div className="absolute top-40 right-20 w-[400px] h-[400px] 
             border-[6px] border-dashed border-orange-100/30 rounded-full 
             animate-[spin_30s_linear_infinite]"></div>
        
        <div className="absolute -bottom-20 -left-20 w-[300px] h-[300px] 
             border-[4px] border-orange-100/40 rounded-full 
             transform rotate-12"></div>

        {/* Dot pattern remains unchanged */}
        <div className="absolute inset-0" style={{
          backgroundImage: `
            radial-gradient(circle, rgba(0,0,0,0.03) 1px, transparent 1px),
            radial-gradient(circle, rgba(0,0,0,0.03) 1px, transparent 1px)
          `,
          backgroundSize: '40px 40px',
          backgroundPosition: '0 0, 20px 20px'
        }}></div>

        {/* Updated squiggly line */}
        <svg className="absolute top-20 left-20 w-40 h-40 text-orange-100/30" viewBox="0 0 100 100">
          <path d="M10,50 Q25,25 40,50 T70,50 T100,50" 
                fill="none" stroke="currentColor" strokeWidth="2"
                className="animate-[dash_20s_linear_infinite]"/>
        </svg>
      </div>

      <div className="max-w-7xl mx-auto px-6 relative z-10">
        <motion.div
          initial="initial"
          whileInView="animate"
          viewport={{ once: true }}
          className="space-y-20"
        >
          <motion.div {...fadeInUp} className="text-center space-y-10">
            {/* Updated Ready to Transform badge */}
            <div className="inline-block">
              <motion.span 
                whileHover={{ scale: 1.05 }}
                className="inline-flex items-center px-8 py-3 rounded-full text-sm font-medium 
                         bg-white border-2 border-slate-200
                         shadow-[4px_4px_0px_0px_rgba(0,0,0,0.1)]
                         hover:shadow-[6px_6px_0px_0px_rgba(0,0,0,0.12)]
                         transform hover:-translate-y-0.5 transition-all duration-300"
              >
                <FontAwesomeIcon icon={faRocket} className="mr-3 animate-pulse text-orange-500" />
                Ready to Transform Your PPC?
              </motion.span>
            </div>

            {/* Updated heading */}
            <h2 className="text-6xl md:text-7xl font-bold tracking-tight text-slate-900 relative">
              TAKE THE NEXT <span className="text-orange-500">STEP</span>
              <svg className="absolute -bottom-4 left-1/2 -translate-x-1/2 w-40" height="10" viewBox="0 0 200 10">
                <path d="M0,5 Q50,2 100,5 T200,5" fill="none" stroke="#f97316" strokeWidth="2"
                      className="animate-[dash_3s_linear_infinite]"/>
              </svg>
            </h2>
            
            <p className="text-lg md:text-xl text-slate-700 max-w-3xl mx-auto 
              font-normal tracking-wide leading-relaxed">
              Whether you're scaling an existing product line or diving into a new category, we're here to help 
              your Amazon advertising make an impact. Partner with TrulyPPC now, and as we open up our 
              proprietary tool for your direct use, you'll be at the front of the line—ready to leverage it for 
              game-changing results.
            </p>
          </motion.div>

          <motion.div {...fadeInUp} className="flex flex-col items-center space-y-10">
            <div className="flex flex-col sm:flex-row gap-8 w-full max-w-2xl mx-auto">
              {/* Updated Primary CTA */}
              <Link to="/audit" className="flex-1">
                <motion.button
                  whileHover={{ scale: 1.03 }}
                  whileTap={{ scale: 0.98 }}
                  className="w-full group relative px-10 py-5 rounded-2xl
                            bg-orange-500 text-white font-semibold 
                            text-base md:text-lg tracking-wide
                            shadow-[6px_6px_0px_0px_rgba(249,115,22,0.3)]
                            hover:shadow-[8px_8px_0px_0px_rgba(249,115,22,0.35)]
                            transform hover:-translate-y-0.5
                            transition-all duration-300"
                >
                  <span className="relative z-10 flex items-center justify-center">
                    Get Your Free PPC Audit
                    <FontAwesomeIcon icon={faArrowRight} 
                      className="ml-2 transition-transform duration-300 group-hover:translate-x-2" 
                    />
                  </span>
                </motion.button>
              </Link>
              
              {/* Secondary CTA */}
              <Link to="/chat" className="flex-1">
                <motion.button
                  whileHover={{ scale: 1.03 }}
                  whileTap={{ scale: 0.98 }}
                  className="w-full group relative px-10 py-5 rounded-2xl
                            bg-white border-2 border-slate-200
                            hover:border-orange-200 text-slate-900 
                            font-semibold text-base md:text-lg tracking-wide
                            shadow-[4px_4px_0px_0px_rgba(0,0,0,0.1)]
                            hover:shadow-[6px_6px_0px_0px_rgba(0,0,0,0.12)]
                            transform hover:-translate-y-0.5
                            transition-all duration-300"
                >
                  <span className="flex items-center justify-center">
                    <FontAwesomeIcon icon={faComments} className="mr-2 group-hover:animate-bounce" />
                    Schedule a strategy call
                  </span>
                </motion.button>
              </Link>
            </div>

            {/* Updated Trust Badge */}
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.7 }}
              className="text-base text-slate-700 flex items-center gap-3 
                        bg-white border-2 border-slate-200 
                        px-6 py-3 rounded-full font-normal tracking-wide
                        shadow-[4px_4px_0px_0px_rgba(0,0,0,0.05)]"
            >
              <svg className="w-5 h-5 text-orange-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                      d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
              </svg>
              100% Secure and Confidential
            </motion.div>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
}

export default NextStep; 