import { 
  faMagnifyingGlassChart,
  faListCheck,
  faGears,
  faLightbulb
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';

export default function HowItWorks() {
  const steps = [
    {
      title: "Start with a Deep Dive",
      desc: "First, we take a close, honest look at your current campaigns and listings. We figure out where you're crushing it, where you're lagging, and where we can make the biggest improvements, right away and over the long haul.",
      icon: faMagnifyingGlassChart,
      color: "from-orange-400 to-orange-500"
    },
    {
      title: "Build Smart Campaigns & Strong Keyword Lists",
      desc: "We don't just toss keywords at the wall. We handpick them based on what we know works, what your shoppers are searching for, and what your competitors might be missing. Then we organize campaigns so we can test and tweak them easily.",
      icon: faListCheck,
      color: "from-orange-500 to-orange-600"
    },
    {
      title: "Constant Refinement, Never Set-and-Forget",
      desc: "Amazon advertising changes fast, and so do we. We're always experimenting with new bids, pruning out underperforming keywords, and trying new ad formats. Our internal tool helps us do it faster and more accurately. Soon, you'll be able to use that same tool to push your own optimizations forward.",
      icon: faGears,
      color: "from-orange-400 to-orange-500"
    },
    {
      title: "Turn Insights into Actionable Advice",
      desc: "We don't just drop a bunch of charts in your inbox. We break down what the data means, show you which areas to double down on, and suggest fresh tactics to keep pushing your growth. We want you to feel confident knowing why we're doing what we're doing—and excited about what comes next.",
      icon: faLightbulb,
      color: "from-orange-500 to-orange-600"
    }
  ];

  return (
    <section id="how-it-works" className="relative py-40 bg-slate-50 overflow-hidden">
      {/* Sketch-style Background */}
      <div className="absolute inset-0">
        {/* Hand-drawn circles */}
        <div className="absolute top-40 right-20 w-[400px] h-[400px] 
             border-[6px] border-dashed border-orange-100/30 rounded-full 
             animate-[spin_30s_linear_infinite]"></div>
        
        <div className="absolute -bottom-20 -left-20 w-[300px] h-[300px] 
             border-[4px] border-orange-100/40 rounded-full 
             transform rotate-12"></div>

        {/* Squiggly decorative lines */}
        <div className="absolute inset-0" style={{
          backgroundImage: `
            radial-gradient(circle, rgba(0,0,0,0.03) 1px, transparent 1px),
            radial-gradient(circle, rgba(0,0,0,0.03) 1px, transparent 1px)
          `,
          backgroundSize: '40px 40px',
          backgroundPosition: '0 0, 20px 20px'
        }}></div>

        {/* Squiggly decorative lines */}
        <svg className="absolute top-20 left-20 w-40 h-40 text-orange-100/30" viewBox="0 0 100 100">
          <path d="M10,50 Q25,25 40,50 T70,50 T100,50" 
                fill="none" stroke="currentColor" strokeWidth="2"
                className="animate-[dash_20s_linear_infinite]"/>
        </svg>
      </div>

      <div className="container mx-auto px-6 relative z-10">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-24"
        >
          <div className="inline-block mb-8">
            <span className="inline-flex items-center px-8 py-3 rounded-full text-sm font-medium 
                           bg-white border-2 border-slate-200
                           shadow-[4px_4px_0px_0px_rgba(0,0,0,0.1)]
                           hover:shadow-[6px_6px_0px_0px_rgba(0,0,0,0.12)]
                           transform hover:-translate-y-0.5 transition-all duration-300">
              <span className="mr-3">🎯</span>
              Our Process
            </span>
          </div>

          <h2 className="text-6xl md:text-7xl font-bold mb-8 text-slate-900 relative">
            Data-Driven <span className="text-orange-500">PPC</span> Optimization
            <svg className="absolute -bottom-4 left-1/2 -translate-x-1/2 w-40" height="10" viewBox="0 0 200 10">
              <path d="M0,5 Q50,2 100,5 T200,5" fill="none" stroke="#f97316" strokeWidth="2"
                    className="animate-[dash_3s_linear_infinite]"/>
            </svg>
          </h2>
          
          <p className="text-xl text-slate-600 max-w-3xl mx-auto font-light leading-relaxed">
            Our systematic approach combines deep analysis with continuous optimization
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 lg:gap-12 max-w-7xl mx-auto">
          {steps.map(({ title, desc, icon, color }, index) => (
            <motion.div 
              key={title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
              className="relative"
            >
              <div className="group relative h-full bg-white p-10 
                            rounded-3xl overflow-hidden
                            border-2 border-slate-200
                            hover:border-slate-300
                            shadow-[6px_6px_0px_0px_rgba(0,0,0,0.05)]
                            hover:shadow-[10px_10px_0px_0px_rgba(0,0,0,0.08)]
                            transform hover:-translate-y-1
                            transition-all duration-500">
                
                {/* Decorative elements */}
                <div className="absolute top-0 right-0 w-40 h-40 
                             border border-dashed border-teal-100 
                             rounded-full -translate-y-20 translate-x-20 
                             group-hover:scale-150 transition-transform duration-500"></div>

                <div className="relative">
                  {/* Enhanced icon styling */}
                  <div className="mb-8 relative">
                    <div className={`w-16 h-16 bg-gradient-to-r ${color} rounded-xl 
                                  flex items-center justify-center transform rotate-3
                                  shadow-[4px_4px_0px_0px_rgba(13,148,136,0.3)]
                                  group-hover:shadow-[6px_6px_0px_0px_rgba(13,148,136,0.35)]
                                  group-hover:rotate-6 transition-all duration-300`}>
                      <FontAwesomeIcon 
                        icon={icon} 
                        className="h-8 w-8 text-white transform -rotate-3 
                                 group-hover:-rotate-6 transition-transform duration-300" 
                      />
                    </div>
                  </div>

                  <h3 className="text-2xl font-bold mb-4 text-slate-800
                             group-hover:text-teal-700 transition-colors duration-300">
                    {title}
                  </h3>
                  <p className="text-slate-600 text-lg leading-relaxed">
                    {desc}
                  </p>
                </div>

                {/* Enhanced connection line - sketch style */}
                {index < steps.length - 1 && (
                  <div className="hidden lg:block absolute top-1/2 -right-6 w-12 transform -translate-y-1/2 z-10">
                    <svg className="w-full" height="4" viewBox="0 0 48 4">
                      <path d="M0,2 Q12,1 24,2 T48,2" 
                            stroke="rgb(45 212 191 / 0.3)" 
                            strokeWidth="2" 
                            fill="none"
                            className="animate-[dash_3s_linear_infinite]"/>
                      <circle cx="46" cy="2" r="2" fill="rgb(45 212 191 / 0.5)" 
                              className="animate-pulse"/>
                    </svg>
                  </div>
                )}
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
} 