import Navbar from '../components/landing/Navbar';
import Footer from '../components/landing/Footer';

export default function PrivacyPolicy() {
  return (
    <div className="bg-white">
      <Navbar />
      <div className="min-h-screen pt-20">
        <div className="max-w-4xl mx-auto px-6 py-16">
          <h1 className="text-3xl font-bold text-slate-900 mb-8">Privacy Policy</h1>
          <p className="text-slate-600 mb-4">Effective Date: 11/12/2024</p>
          
          <p className="text-slate-700 mb-8">
            TrulyPPC ("we," "us," or "our"), owned and operated by Ashutosh Singh under Udyam Registration Number UDYAM-CG-14-0093047, respects your privacy and is committed to protecting the personal information you share with us. This Privacy Policy outlines how we collect, use, and protect your data when you visit our website (the "Site") or use our services.
          </p>

          {/* Section 1: Information We Collect */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-slate-900 mb-4">1. Information We Collect</h2>
            <p className="text-slate-700 mb-4">We collect both personal and non-personal information to provide and improve our services:</p>
            
            <h3 className="text-xl font-semibold text-slate-800 mb-2">Personal Information:</h3>
            <p className="text-slate-700 mb-4">When you interact with our Site or services, we may collect information such as:</p>
            <ul className="list-disc pl-6 text-slate-700 mb-4">
              <li>Name</li>
              <li>Email address</li>
              <li>Phone number</li>
              <li>Billing and payment information</li>
              <li>Amazon advertiser or seller ID (if applicable)</li>
            </ul>

            <h3 className="text-xl font-semibold text-slate-800 mb-2">Non-Personal Information:</h3>
            <p className="text-slate-700 mb-4">We may also collect non-identifiable information, such as:</p>
            <ul className="list-disc pl-6 text-slate-700">
              <li>Browser type and version</li>
              <li>IP address</li>
              <li>Pages visited and time spent on our Site</li>
              <li>Device information</li>
              <li>Cookies and other tracking technologies</li>
            </ul>
          </section>

          {/* Section 2: How We Use Your Information */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-slate-900 mb-4">2. How We Use Your Information</h2>
            <p className="text-slate-700 mb-4">We use the information we collect to:</p>
            <ul className="list-disc pl-6 text-slate-700">
              <li>Provide, manage, and improve our services</li>
              <li>Respond to inquiries or support requests</li>
              <li>Process payments and complete transactions</li>
              <li>Analyze Site usage and improve user experience</li>
              <li>Send promotional communications (you can opt out at any time)</li>
              <li>Comply with legal obligations</li>
            </ul>
          </section>

          {/* Section 3: Sharing Your Information */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-slate-900 mb-4">3. Sharing Your Information</h2>
            <p className="text-slate-700 mb-4">We do not sell your personal information to third parties. However, we may share your data under the following circumstances:</p>
            <ul className="list-disc pl-6 text-slate-700">
              <li><span className="font-medium">Service Providers:</span> Trusted third-party providers who assist with payment processing, analytics, and other operational needs.</li>
              <li><span className="font-medium">Legal Requirements:</span> When required by law, subpoena, or court order.</li>
              <li><span className="font-medium">Business Transactions:</span> If TrulyPPC is involved in a merger, acquisition, or asset sale, your information may be transferred to the new entity.</li>
            </ul>
          </section>

          {/* Continue with sections 4-11 */}
          {/* Section 4: Cookies and Tracking Technologies */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-slate-900 mb-4">4. Cookies and Tracking Technologies</h2>
            <p className="text-slate-700 mb-4">We use cookies and similar technologies to:</p>
            <ul className="list-disc pl-6 text-slate-700 mb-4">
              <li>Enhance your browsing experience</li>
              <li>Analyze traffic and user behavior</li>
              <li>Track preferences and personalize content</li>
            </ul>
            <p className="text-slate-700">You can control or disable cookies through your browser settings. Note that disabling cookies may impact your experience on the Site.</p>
          </section>

          {/* Add remaining sections 5-11 similarly */}
          {/* Section 5: Data Retention */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-slate-900 mb-4">5. Data Retention</h2>
            <p className="text-slate-700">We retain your personal information only as long as necessary to fulfill the purposes outlined in this policy or as required by law. Non-personal information may be retained for analytics and research.</p>
          </section>

          {/* Section 6: Data Security */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-slate-900 mb-4">6. Data Security</h2>
            <p className="text-slate-700">We implement industry-standard security measures to protect your data from unauthorized access, alteration, or disclosure. However, no method of electronic storage or transmission is completely secure, and we cannot guarantee absolute security.</p>
          </section>

          {/* Section 7: Your Rights */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-slate-900 mb-4">7. Your Rights</h2>
            <p className="text-slate-700 mb-4">You have the right to:</p>
            <ul className="list-disc pl-6 text-slate-700">
              <li>Access, update, or delete your personal information</li>
              <li>Opt out of receiving promotional emails</li>
              <li>Withdraw consent for data processing (where applicable)</li>
            </ul>
            <p className="text-slate-700 mt-4">To exercise these rights, contact us at contact@trulyppc.com.</p>
          </section>

          {/* Sections 8-11 */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-slate-900 mb-4">8. Links to Third-Party Sites</h2>
            <p className="text-slate-700">Our Site may contain links to external websites. We are not responsible for the privacy practices or content of such third-party sites. Please review their privacy policies before providing your information.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-slate-900 mb-4">9. Children's Privacy</h2>
            <p className="text-slate-700">Our Site and services are not directed at individuals under the age of 13. We do not knowingly collect personal information from children. If you believe we have inadvertently collected such information, please contact us immediately.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-slate-900 mb-4">10. Changes to This Privacy Policy</h2>
            <p className="text-slate-700">We may update this Privacy Policy from time to time. Changes will be posted on this page, and the "Effective Date" will be updated. We encourage you to review this policy periodically.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-slate-900 mb-4">11. Contact Us</h2>
            <p className="text-slate-700 mb-4">If you have questions or concerns about this Privacy Policy, contact us at:</p>
            <p className="text-slate-700">Email: contact@trulyppc.com</p>
          </section>

          <p className="text-slate-600 mt-8">Last Updated: 11/12/2024</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
