import { motion } from "framer-motion";
import { faQuestionCircle, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

function FAQ() {
  const [openIndex, setOpenIndex] = useState(null);

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.8, ease: "easeOut" }
  };

  const faqs = [
    {
      question: "When can I try your internal PPC tool myself?",
      answer: "We currently use it behind the scenes for our clients, and we'll soon make it available for self-service. Keep an eye out for the announcement—you'll love having this level of insight at your fingertips."
    },
    {
      question: "How fast will I see improvements?",
      answer: "It varies, but many clients notice better results—like improved CTR or a dip in ACoS—within the first month or so. Over time, as we refine and optimize, those gains tend to get even better."
    },
    {
      question: "Can you handle all types of Amazon ads?",
      answer: "Absolutely. We work with Sponsored Products, Sponsored Brands, Sponsored Display, and DSP. We design a mix that reaches your customers wherever they are in their journey."
    },
    {
      question: "Do I have to sign a long-term contract?",
      answer: "Nope. We think our work should speak for itself, so we keep things flexible and aim to earn your trust month after month."
    }
  ];

  return (
    <section className="relative py-32 bg-slate-50 overflow-hidden">
      {/* Updated Background Elements */}
      <div className="absolute inset-0">
        {/* Hand-drawn circles */}
        <div className="absolute top-40 right-20 w-[400px] h-[400px] 
             border-[6px] border-dashed border-orange-100/30 rounded-full 
             animate-[spin_30s_linear_infinite]"></div>
        
        <div className="absolute -bottom-20 -left-20 w-[300px] h-[300px] 
             border-[4px] border-orange-100/40 rounded-full 
             transform rotate-12"></div>

        {/* Dot pattern remains unchanged */}
        <div className="absolute inset-0" style={{
          backgroundImage: `
            radial-gradient(circle, rgba(0,0,0,0.03) 1px, transparent 1px),
            radial-gradient(circle, rgba(0,0,0,0.03) 1px, transparent 1px)
          `,
          backgroundSize: '40px 40px',
          backgroundPosition: '0 0, 20px 20px'
        }}></div>

        {/* Updated squiggly line */}
        <svg className="absolute top-20 left-20 w-40 h-40 text-orange-100/30" viewBox="0 0 100 100">
          <path d="M10,50 Q25,25 40,50 T70,50 T100,50" 
                fill="none" stroke="currentColor" strokeWidth="2"
                className="animate-[dash_20s_linear_infinite]"/>
        </svg>
      </div>

      <div className="max-w-7xl mx-auto px-6 relative z-10">
        <motion.div
          initial="initial"
          whileInView="animate"
          viewport={{ once: true }}
          className="space-y-16"
        >
          {/* Updated Heading Section */}
          <motion.div {...fadeInUp} className="text-center space-y-8">
            <div className="inline-block mb-4">
              <span className="inline-flex items-center px-8 py-3 rounded-full 
                           text-base font-medium tracking-wide text-slate-900
                           bg-white border-2 border-slate-200
                           shadow-[4px_4px_0px_0px_rgba(0,0,0,0.1)]
                           hover:shadow-[6px_6px_0px_0px_rgba(0,0,0,0.12)]
                           transform hover:-translate-y-0.5 transition-all duration-300">
                <FontAwesomeIcon icon={faQuestionCircle} 
                  className="mr-2 animate-bounce text-orange-500" />
                Common Questions
              </span>
            </div>

            <h2 className="text-5xl md:text-6xl font-bold tracking-tight text-slate-900 relative">
              Frequently Asked <span className="text-orange-500">Questions</span>
              <svg className="absolute -bottom-4 left-1/2 -translate-x-1/2 w-40" height="10" viewBox="0 0 200 10">
                <path d="M0,5 Q50,2 100,5 T200,5" fill="none" stroke="#f97316" strokeWidth="2"
                      className="animate-[dash_3s_linear_infinite]"/>
              </svg>
            </h2>
          </motion.div>

          {/* Updated FAQ Items */}
          <div className="space-y-6 max-w-4xl mx-auto">
            {faqs.map((faq, index) => (
              <motion.div
                key={index}
                {...fadeInUp}
                transition={{ delay: index * 0.1 }}
                className="group relative"
              >
                <button
                  onClick={() => setOpenIndex(openIndex === index ? null : index)}
                  className="w-full text-left"
                >
                  <div className="relative bg-white p-8 rounded-3xl
                              border-2 border-slate-200
                              hover:border-orange-200
                              shadow-[6px_6px_0px_0px_rgba(0,0,0,0.05)]
                              hover:shadow-[10px_10px_0px_0px_rgba(0,0,0,0.08)]
                              transform hover:-translate-y-1
                              transition-all duration-300">
                    
                    {/* Updated decorative circle */}
                    <div className="absolute top-0 right-0 w-40 h-40 
                                border border-dashed border-orange-100 
                                rounded-full -translate-y-20 translate-x-20 
                                group-hover:scale-150 transition-transform duration-500"></div>

                    <div className="relative">
                      <div className="flex justify-between items-center">
                        <h3 className="text-xl md:text-2xl font-bold text-slate-900 
                                   tracking-wide group-hover:text-orange-500 
                                   transition-colors duration-300">
                          {faq.question}
                        </h3>
                        {/* Updated chevron container */}
                        <div className={`w-8 h-8 flex items-center justify-center
                                    bg-orange-50 rounded-lg
                                    transform rotate-3 group-hover:rotate-6
                                    transition-all duration-300
                                    border-2 border-orange-200`}>
                          <FontAwesomeIcon 
                            icon={faChevronDown}
                            className={`w-4 h-4 text-orange-500 transition-all duration-300
                                    transform -rotate-3 group-hover:-rotate-6
                                    ${openIndex === index ? 'rotate-180' : ''}`}
                          />
                        </div>
                      </div>
                      
                      {/* Updated answer section */}
                      <motion.div
                        initial={false}
                        animate={{ 
                          height: openIndex === index ? 'auto' : 0, 
                          opacity: openIndex === index ? 1 : 0 
                        }}
                        transition={{ duration: 0.3 }}
                        className="overflow-hidden"
                      >
                        <div className="mt-6 pl-6 border-l-2 border-orange-200">
                          <p className="text-base md:text-lg text-slate-700 
                                        leading-relaxed tracking-wide font-normal">
                            {faq.answer}
                          </p>
                        </div>
                      </motion.div>
                    </div>
                  </div>
                </button>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </section>
  );
}

export default FAQ; 