import { faChartLine, faBoltLightning, faRocket, faComments, faFunnelDollar, faArrowTrendUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';

export default function Features() {
    const features = [
      {
        title: "We're All-In on Amazon PPC",
        description: "While other agencies dabble in a bit of everything, we've chosen to go all-in on Amazon PPC. We love this world of keywords, bids, and conversions, and by staying laser-focused, we've gotten really good at helping you stand out and earn more.",
        icon: faChartLine
      },
      {
        title: "Real Decisions, Powered by Real Data",
        description: "We let the numbers tell us what's working and what's not. Every keyword, every bid strategy, and every ad placement comes from looking closely at the data—then adding our human judgment to find what truly clicks for your brand.",
        icon: faBoltLightning
      },
      {
        title: "Our Tech is Your Edge",
        description: "We've built a proprietary tool that helps us spot patterns, optimize bids in real-time, and discover untapped keyword opportunities. Right now",
        icon: faRocket
      },
      {
        title: "No Black Boxes, Just Honest Conversations",
        description: "We're big believers in being open and direct. Your dedicated account manager will share straightforward reports, walk you through the numbers, and explain why we're making certain moves. No jargon or smoke and mirrors—just honest, ongoing dialogue.",
        icon: faComments  // You'll need to import this
      },
      {
        title: "We Cover the Whole Funnel",
        description: "From Sponsored Products and Sponsored Brands to Sponsored Display and even DSP, we'll help you reach shoppers at every stage of their journey. Whether they're just browsing or ready to click Buy Now, we'll position your products in front of the right eyes.",
        icon: faFunnelDollar  // You'll need to import this
      },
      {
        title: "We Grow With You for the Long Haul",
        description: "Our goal goes beyond short-term wins. We stick around for continuous collaboration, bringing fresh ideas to the table and helping you stay agile in Amazon’s ever-evolving marketplace. If you want a partner that’s ready to scale with you, that’s us.",
        icon: faArrowTrendUp  // You'll need to import this
      }
    ];
  
    const containerVariants = {
      hidden: { opacity: 0 },
      visible: {
        opacity: 1,
        transition: { staggerChildren: 0.2 }
      }
    };

    const itemVariants = {
      hidden: { y: 20, opacity: 0 },
      visible: {
        y: 0,
        opacity: 1,
        transition: { duration: 0.5 }
      }
    };
  
    return (
      <section className="relative py-52 bg-slate-50 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute top-40 right-20 w-[400px] h-[400px] 
               border-[6px] border-dashed border-orange-100/30 rounded-full 
               animate-[spin_30s_linear_infinite]"></div>
          
          <div className="absolute -bottom-20 -left-20 w-[300px] h-[300px] 
               border-[4px] border-orange-100/40 rounded-full 
               transform rotate-12"></div>

          <div className="absolute inset-0" style={{
            backgroundImage: `
              radial-gradient(circle, rgba(0,0,0,0.03) 1px, transparent 1px),
              radial-gradient(circle, rgba(0,0,0,0.03) 1px, transparent 1px)
            `,
            backgroundSize: '40px 40px',
            backgroundPosition: '0 0, 20px 20px'
          }}></div>

          <svg className="absolute top-20 left-20 w-40 h-40 text-orange-100/30" viewBox="0 0 100 100">
            <path d="M10,50 Q25,25 40,50 T70,50 T100,50" 
                  fill="none" stroke="currentColor" strokeWidth="2"
                  className="animate-[dash_20s_linear_infinite]"/>
          </svg>
        </div>

        <motion.div 
          className="container mx-auto px-6 relative z-10 max-w-7xl"
          initial="hidden"
          animate="visible"
          variants={containerVariants}
        >
          <motion.div className="text-center mb-32" variants={itemVariants}>
            <div className="inline-block mb-8">
              <span className="inline-flex items-center px-8 py-3 rounded-full text-sm font-medium 
                           bg-white border-2 border-slate-200
                           shadow-[4px_4px_0px_0px_rgba(0,0,0,0.1)]
                           hover:shadow-[6px_6px_0px_0px_rgba(0,0,0,0.12)]
                           transform hover:-translate-y-0.5 transition-all duration-300">
                <span className="mr-3">✨</span>
                Why Choose Us
                <span className="ml-3">✨</span>
              </span>
            </div>

            <h2 className="text-6xl md:text-7xl font-bold mb-8 relative text-slate-900">
              Why Truly<span className="text-orange-500">PPC</span>?
              <svg className="absolute -bottom-4 left-1/2 -translate-x-1/2 w-40" height="10" viewBox="0 0 200 10">
                <path d="M0,5 Q50,2 100,5 T200,5" fill="none" stroke="#f97316" strokeWidth="2"
                      className="animate-[dash_3s_linear_infinite]"/>
              </svg>
            </h2>
            <p className="text-lg md:text-xl text-slate-700 max-w-3xl mx-auto 
                          leading-relaxed font-normal tracking-wide">
              With e-commerce becoming more competitive every day, standing out requires precision, speed, and data-driven strategies.
            </p>
          </motion.div>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-8 md:gap-12">
            {features.map((feature, index) => (
              <motion.div 
                key={index}
                variants={itemVariants}
                className="group relative bg-white p-10 
                          rounded-3xl overflow-hidden
                          transition-all duration-500 ease-out
                          border-2 border-slate-200
                          hover:border-orange-200
                          shadow-[6px_6px_0px_0px_rgba(0,0,0,0.05)]
                          hover:shadow-[10px_10px_0px_0px_rgba(0,0,0,0.08)]
                          transform hover:-translate-y-1"
              >
                <div className="absolute top-0 right-0 w-40 h-40 
                             border border-dashed border-orange-100 
                             rounded-full -translate-y-20 translate-x-20 
                             group-hover:scale-150 transition-transform duration-500"></div>

                <div className="relative mb-8">
                  <div className="relative flex items-center justify-center w-16 h-16 
                                bg-gradient-to-br from-orange-500 to-orange-600 
                                rounded-xl transform rotate-3 
                                shadow-[4px_4px_0px_0px_rgba(249,115,22,0.3)]
                                group-hover:shadow-[6px_6px_0px_0px_rgba(249,115,22,0.35)]
                                group-hover:rotate-6 transition-all duration-300">
                    <FontAwesomeIcon 
                      icon={feature.icon} 
                      className="h-8 w-8 text-white transform -rotate-3 
                               group-hover:-rotate-6 transition-transform duration-300" 
                    />
                  </div>
                </div>

                <div className="relative">
                  <h3 className="text-2xl font-bold mb-4 text-slate-900
                                group-hover:text-orange-500
                                transition-all duration-300">
                    {feature.title}
                  </h3>
                  <p className="text-base md:text-lg text-slate-700 
                                leading-relaxed tracking-wide font-normal">
                    {feature.description}
                  </p>

                  <div className="mt-8 opacity-0 transform translate-y-4 
                                group-hover:opacity-100 group-hover:translate-y-0 
                                transition-all duration-300">
                    <span className="inline-flex items-center text-orange-500 
                                     font-semibold text-lg tracking-wide
                                     border-b-2 border-transparent hover:border-orange-500
                                     transition-all duration-300">
                      Learn more
                      <svg className="w-4 h-4 ml-2 group-hover:translate-x-2 transition-transform" 
                           fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                      </svg>
                    </span>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </section>
    );
  } 