import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faArrowRight } from '@fortawesome/free-solid-svg-icons';

export default function Footer() {
  const socialLinks = [
    { icon: faLinkedin, href: "https://www.linkedin.com/company/trulyppc/about/?viewAsMember=true", label: "LinkedIn" },
    { icon: faEnvelope, href: "mailto:contact@trulyppc.com", label: "Email" },
  ];

  return (
    <footer className="relative bg-slate-50 overflow-hidden">
      {/* Updated Background Elements */}
      <div className="absolute inset-0">
        {/* Hand-drawn circles */}
        <div className="absolute top-40 right-20 w-[400px] h-[400px] 
             border-[6px] border-dashed border-orange-100/30 rounded-full 
             animate-[spin_30s_linear_infinite]"></div>
        
        <div className="absolute -bottom-20 -left-20 w-[300px] h-[300px] 
             border-[4px] border-orange-100/40 rounded-full 
             transform rotate-12"></div>

        {/* Dot pattern remains unchanged */}
        <div className="absolute inset-0" style={{
          backgroundImage: `
            radial-gradient(circle, rgba(0,0,0,0.03) 1px, transparent 1px),
            radial-gradient(circle, rgba(0,0,0,0.03) 1px, transparent 1px)
          `,
          backgroundSize: '40px 40px',
          backgroundPosition: '0 0, 20px 20px'
        }}></div>

        {/* Updated squiggly line */}
        <svg className="absolute top-20 left-20 w-40 h-40 text-orange-100/30" viewBox="0 0 100 100">
          <path d="M10,50 Q25,25 40,50 T70,50 T100,50" 
                fill="none" stroke="currentColor" strokeWidth="2"
                className="animate-[dash_20s_linear_infinite]"/>
        </svg>
      </div>

      <div className="relative max-w-7xl mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 py-20">
          {/* Updated Left Section */}
          <div className="space-y-8">
            {/* Updated Logo */}
            <div className="inline-block relative">
              <span className="text-3xl font-bold text-slate-900 relative">
                Truly<span className="text-orange-500">PPC</span>
                <svg className="absolute -bottom-2 w-full" height="6" viewBox="0 0 100 6">
                  <path d="M0,3 Q25,0 50,3 T100,3" fill="none" stroke="#f97316" strokeWidth="2"
                        className="animate-[dash_3s_linear_infinite]"/>
                </svg>
              </span>
            </div>
            
            {/* Description remains unchanged except for font weight */}
            <p className="text-base md:text-lg text-slate-700 max-w-md 
                          font-normal tracking-wide leading-relaxed">
              Revolutionizing Amazon PPC management with AI-driven solutions.
            </p>

            {/* Updated Social Links */}
            <div className="flex gap-6">
              {socialLinks.map((social, index) => (
                <a
                  key={index}
                  href={social.href}
                  aria-label={social.label}
                  className="group"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="p-3 rounded-xl bg-white
                              border-2 border-slate-200
                              shadow-[4px_4px_0px_0px_rgba(0,0,0,0.1)]
                              hover:shadow-[6px_6px_0px_0px_rgba(0,0,0,0.12)]
                              transform rotate-3 hover:rotate-6
                              hover:-translate-y-1
                              transition-all duration-300">
                    <FontAwesomeIcon 
                      icon={social.icon} 
                      className="w-5 h-5 text-orange-500 transform -rotate-3 
                               group-hover:-rotate-6 transition-transform duration-300" 
                    />
                  </div>
                </a>
              ))}
            </div>
          </div>

          {/* Updated Right Section - Newsletter */}
          <div className="flex flex-col justify-center space-y-6">
            <div className="space-y-2">
              <h3 className="text-xl md:text-2xl font-semibold tracking-wide
                             text-slate-900 relative inline-block">
                Stay <span className="text-orange-500">Updated</span>
                <svg className="absolute -bottom-2 w-full" height="6" viewBox="0 0 100 6">
                  <path d="M0,3 Q25,0 50,3 T100,3" fill="none" stroke="#f97316" strokeWidth="2"
                        className="animate-[dash_3s_linear_infinite]"/>
                </svg>
              </h3>
              <p className="text-base md:text-lg text-slate-700 
                            font-normal tracking-wide leading-relaxed">
                Get notified about our self-service launch and new features.
              </p>
            </div>

            {/* Updated Email Input */}
            <div className="flex max-w-md">
              <input
                type="email"
                placeholder="Enter your email"
                className="flex-1 px-6 py-3 bg-white 
                       border-2 border-slate-200 rounded-l-xl 
                       text-slate-800 text-base tracking-wide
                       placeholder:text-slate-400 placeholder:font-normal
                       focus:outline-none focus:border-orange-300
                       shadow-[4px_4px_0px_0px_rgba(0,0,0,0.05)]
                       transition-all duration-300"
              />
              <button className="px-6 py-3 bg-orange-500
                             text-white rounded-r-xl 
                             shadow-[4px_4px_0px_0px_rgba(249,115,22,0.3)]
                             hover:shadow-[6px_6px_0px_0px_rgba(249,115,22,0.35)]
                             transform hover:-translate-y-0.5
                             transition-all duration-300 group">
                <FontAwesomeIcon 
                  icon={faArrowRight} 
                  className="w-5 h-5 group-hover:translate-x-1 transition-transform duration-300" 
                />
              </button>
            </div>
          </div>
        </div>

        {/* Updated Bottom Section */}
        <div className="border-t-2 border-slate-200 py-8">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <div className="text-base text-slate-700 tracking-wide">
              © 2024 TrulyPPC. All rights reserved.
            </div>
            <div className="flex gap-8">
              <a href="/privacy-policy" className="text-base text-slate-700 hover:text-orange-500 
                                 tracking-wide font-normal
                                 border-b border-transparent hover:border-orange-500
                                 transition-all duration-300">
                Privacy Policy
              </a>
              <a href="/terms-of-service" className="text-base text-slate-700 hover:text-orange-500 
                                 tracking-wide font-normal
                                 border-b border-transparent hover:border-orange-500
                                 transition-all duration-300">
                Terms of Service
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
} 