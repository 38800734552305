import Hero from '../components/landing/Hero';
import Features from '../components/landing/Features';
import HowItWorks from '../components/landing/HowItWorks';
// import Pricing from '../components/landing/Pricing';
import Footer from '../components/landing/Footer';
import Navbar from '../components/landing/Navbar';
import Technology from '../components/landing/Technology';
import NextStep from '../components/landing/NextStep';
import FAQ from '../components/landing/FAQ';

function LandingPage() {
  return (
    <div className="bg-gray-50 text-gray-900">
      <Navbar />
      <Hero />
      <Features />
      <Technology />
      <HowItWorks />
      <NextStep />
      <FAQ />
      <Footer />
    </div>
  );
}

export default LandingPage; 